/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/dark.css';
@import '~@swimlane/ngx-datatable/themes/bootstrap.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';


*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
    
}
.ion-grid{
    margin: 0rem 1rem 0rem 1rem; 
}

//ngx-datatable settings ===>>> start
/* change bootstrap even rows color in data table */
.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even{
    background-color: #d5e9e7;  
}
/*set the border to 0 for each row in data table*/
.ngx-datatable.bootstrap .datatable-body .datatable-body-row{
   border: 0px;
}

ngx-datatable{
    color: #233d57;
    align-items: center;
}

datatable-header{
    font-weight: bold;
    background-color: #80c2bc;
    border: 0px;
    border-radius: 5px 5px 0 0 ;    
}

.datatable-body-row{
    font-weight:400;
}

ion-icon {
       &.bigger {
      width: 1.06rem !important;
      height: 1.06rem !important ;
    }
}
 
// <<===ngx-datatable settings finished 

ion-item{
    --border-color: #80c2bc;
    }
ion-toolbar {
  height: 2.5rem;
  align-items: center ;
    }
/* to remove border-bottom in header toolbar */
ion-header.no-after::after {
        display: none;
      }


/////alert
/* DOES NOT WORK - not specific enough */
.alert-wrapper {
    background: #e5e5e5;
  }
   
  /* Works - pass "my-custom-class" in cssClass to increase specificity */
  .my-custom-class .alert-wrapper {
    background: #e5e5e5;
  }

  .my-custom-class {
    --background: #e5e5e5;
  }
  






