// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #80c2bc;
  --ion-color-primary-rgb: 128,194,188;
  --ion-color-primary-contrast: #233d57;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #71aba5;
  --ion-color-primary-tint: #8dc8c3;
  
  --ion-color-secondary: #d5e9e7;
  --ion-color-secondary-rgb: 213,233,231;
  --ion-color-secondary-contrast: #233d57;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #bbcdcb;
  --ion-color-secondary-tint: #d9ebe9;
  
  --ion-color-tertiary: #4eb2ac;
  --ion-color-tertiary-rgb: 78,178,172;
  --ion-color-tertiary-contrast: #233d57;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #459d97;
  --ion-color-tertiary-tint: #60bab4;
  
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #dadada;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
   
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #233d57;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #dadada;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  
  --ion-color-dark: #233d57;
  --ion-color-dark-rgb: 35,61,87;
  --ion-color-dark-contrast: #dadada;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1f364d;
  --ion-color-dark-tint: #395068;
  
  --ion-color-medium: #c6c6c6;
  --ion-color-medium-rgb: 198,198,198;
  --ion-color-medium-contrast: #233d57;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #aeaeae;
  --ion-color-medium-tint: #cccccc;
  
  --ion-color-light: #dadada;
  --ion-color-light-rgb: 218,218,218;
  --ion-color-light-contrast: #233d57;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #c0c0c0;
  --ion-color-light-tint: #dedede;

 
  }
  
